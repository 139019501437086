<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/user/fictitious' }"
          >虚拟用户</el-breadcrumb-item
        >
        <el-breadcrumb-item>添加</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!--内容-->
    <div class="page-content-x">
      <el-row>
        <el-form size="medium" ref="form" :label-width="this.env.label_width">
          <el-col :span="24">
            <el-form-item label="昵称">
              <el-input
                type="textarea"
                v-model="form.user_nick"
                placeholder="张三，李四，王五"
              ></el-input>
              <p style="color: #fc142f">
                添加多个昵称时请使用“,”进行分割（昵称中英文皆可）如：张三，李四，王五
              </p>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="头像">
              <BatchQnupload
                v-model="form.avatar_url"
                :value="form.avatar_url"
                :sum="50"
                @batchDel="batchDel"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item>
              <el-button
                v-if="is_auth('user.fictitious.isaddall')"
                size="medium"
                type="primary"
                @click="save"
                >保存
              </el-button>
              <el-button size="medium" @click="isreturn">返回</el-button>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
    </div>
  </div>
</template>
<script>
import BatchQnupload from "@/components/BatchQnupload";

export default {
  components: {
    BatchQnupload,
  },
  data() {
    return {
      form: {
        gender: "1",
        avatar_url: [],
        create_time: "",
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  // 创建
  created() {},
  // 安装
  mounted() {},
  methods: {
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    save() {
      const { form } = this;
      if (form.user_nick) {
        let nameArray = form.user_nick.replace(/，/g, ",").split(",");
        nameArray.map((item, index) => {
          if (item === "") {
            nameArray.splice(index, 1);
          }
        });
        const nameLength = nameArray.length,
          avatarLength = form.avatar_url.length,
          // 判断昵称数组和头像数组的长度，并赋值
          largeArray = nameLength < avatarLength ? form.avatar_url : nameArray,
          smallArray = nameLength < avatarLength ? nameArray : form.avatar_url;
        let userData = [];
        // 循环存储虚拟用户信息
        for (let i in smallArray) {
          for (let j in largeArray) {
            if (i === j) {
              userData = userData.concat({
                user_nick: nameLength < avatarLength ? smallArray[j] : largeArray[j],
                avatar_url: nameLength < avatarLength ? largeArray[j] : smallArray[j],
              });
            }
          }
        }
        if (userData.length > 0) {
          let postdata = {
            api_name: "user.fictitious.isaddall",
            token: this.Tool.get_l_cache("token"),
            userall: userData,
          };
          this.loading = true;
          this.Tool.post_data("oss", postdata, (json) => {
            this.loading = false;
            if (json.code === 0) {
              this.$message({
                message: `成功添加个${userData.length}虚拟用户`,
                type: "success",
                duration: this.env.message_duration,
                onClose: () => {
                  this.$router.push({ path: "/user/fictitious" });
                },
              });
            } else {
              this.Tool.errormes(json);
            }
          });
        } else {
          this.$message.error("添加虚拟用户数量不能为0！");
        }
      } else {
        this.$message.error("添加虚拟用户数量不能为0！");
      }
    },
    // 返回
    isreturn() {
      this.$router.go(-1);
    },
    // 批量删除成功
    batchDel() {
      this.form.avatar_url = [];
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
