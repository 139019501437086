<template>
  <div>
    <!--图片-->
    <!-- upload 多张 -->
    <div class="upload">
      <div v-for="(item, i) in value" :key="i">
        <el-image :src="item" :preview-src-list="value"></el-image>
        <el-button v-if="!readonly" class="uploader-delete" type="text" @click="del(i)"
          ><i class="el-icon-delete">&nbsp;&nbsp;删除</i>
        </el-button>
      </div>
      <el-upload
        v-if="!readonly"
        class="el-upload"
        :limit="sum"
        :action="url"
        :multiple="true"
        :show-file-list="false"
        :before-upload="getUploadtoken"
      >
        <i class="el-icon-picture-outline avatar-uploader-icon"></i>
        <span class="upload-sum">{{ value.length }}/{{ sum }}</span>
      </el-upload>
      <div style="height: 158px"></div>
    </div>
    <el-button v-if="this.value.length > 0" type="danger" @click="batchDel"
      >批量删除</el-button
    >
  </div>
</template>

<script>
import env from "@/../config/env";
import apiurl from "@/../config/apiurl";
import axios from "axios";

export default {
  name: "Qnupload",
  // 父组件参数
  props: {
    value: {
      type: [Array, String, Object],
      default: "",
    },
    sum: {
      type: Number,
      default: 1,
    },
    type: {
      type: String,
      default: "image", // 默认图片
    },
    readonly: {
      type: null,
      default: false,
    },
    compress: {
      // 是否压缩，默认是
      type: null,
      default: true,
    },
    imgsize: {
      // 图片压缩尺寸，
      type: String,
      default: "compress", // 默认仅压缩不改变尺寸,lg:1024px,sm:640px,xs:320px
    },
  },
  data() {
    return {
      clipper_config: {}, // 裁剪配置
      file_name: "", // 文件名
      token_info: {}, // token信息
      //   img_max_sum: 9, // 图片最大数量
      url: env.API_URL + apiurl.oss.url,
    };
  },
  // 创建
  created() {
    // 裁剪尺寸判断
    if (this.fixedNumber) {
      this.clipper_config = {
        fixed: true,
        fixedBox: true,
        fixedNumber: this.fixedNumber,
      };
    }

    if (this.sum < this.img_max_sum) {
      this.img_max_sum = this.sum;
    }
  },
  // 安装
  mounted() {},
  methods: {
    // 获取token
    getUploadtoken(file) {
      // console.log('上传内容',file)
      let thi = this;
      // 获取上传token
      let postData = {
        api_name: "home.upload.gettoken",
        token: this.Tool.get_l_cache("token"),
        name: file.name,
        type: this.type,
      };
      this.Tool.post_data("oss", postData, (json) => {
        if (json.code === 0) {
          if (this.isclipper) {
            this.file_name = file.name; // 保存文件名
            this.token_info = json.data; // 保存上传token信息
            // 需要裁剪
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function (e) {
              // target.result 该属性表示目标对象的DataURL
              // console.log(e.target.result);
              thi.clipperx(e.target.result);
            };
          } else {
            this.upqiniu(json.data, file);
          }
        } else {
          this.$message.error(json.message);
        }
      });
    },
    // 上传
    upqiniu(tokeninfo, file) {
      // console.log('文件信息',file)

      // 判断文件大小
      const issize = file.size / 1024 / 1024 < tokeninfo.size;
      if (!issize) {
        this.$message.warning("不能超过 " + tokeninfo.size + "MB");
        return false;
      }

      const formdata = new FormData();
      formdata.append("file", file);
      formdata.append("token", tokeninfo.token);
      formdata.append("key", tokeninfo.kyename);
      const headers = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      // 获取到凭证之后再将文件上传到七牛云空间
      axios.post(tokeninfo.uploadurl, formdata, headers).then((res) => {
        let url = tokeninfo.domain + res.data.key;
        if (this.compress && !this.Tool.is_empty(tokeninfo.imgsize)) {
          // 需要压缩
          if (this.Tool.is_empty(tokeninfo.imgsize[this.imgsize])) {
            // 判断压缩的尺寸是否存在
            url += tokeninfo.imgsize.compress; // 仅压缩，不改变尺寸
          } else {
            url += tokeninfo.imgsize[this.imgsize]; // 按照尺寸进行压缩
          }
        }
        this.value.push(url);
        // console.log('上传文件地址', url)
        // if (this.sum > 1) {
        //   console.log(url);

        // } else {
        //   this.$emit("input", url);
        // }
      });
    },
    // 删除页面数据
    del(i = 0) {
      // 多张
      let key = this.value[i];
      this.isdel(key);
      this.value.splice(i, 1);
    },
    // 删除云端数据
    isdel(key = "x") {
      key = new URL(key).pathname.substr(1);
      let postData = {
        api_name: "home.upload.isdel",
        token: this.Tool.get_l_cache("token"),
        key: key,
      };
      this.Tool.post_data("oss", postData, (json) => {
        if (json.code !== 0) {
          this.$message.error(json.message);
        }
      });
    },
    // 批量删除
    batchDel() {
      let index = 0;
      for (let i in this.value) {
        index += 1;
        this.isdel(this.value[i]);
      }
      if (index === this.value.length) {
        this.$emit("batchDel");
        this.$message.success("删除成功");
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.upload {
  display: flex;
  flex-wrap: wrap;
}

.upload .el-image {
  position: unset !important;
  width: 150px !important;
  height: 120px !important;
  border: 1px solid #8c939d;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

>>> .upload .el-image__inner {
  height: auto !important;
}

.uploader-delete {
  color: #f56c6c;
  text-align: center;
  margin: auto;
}

.upload .el-upload {
  border: 1px dashed #8c939d;
  border-radius: 6px;
  width: 150px !important;
  height: 120px !important;
}

.upload .avatar-uploader-icon {
  font-size: 40px;
  color: #8c939d;
  width: 150px !important;
  height: 120px !important;
  line-height: 120px !important;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

>>> .el-image__error {
  width: 150px !important;
  height: 120px !important;
}

.upload-sum {
  display: block;
  line-height: 38px;
  color: #8c939d;
}
</style>
